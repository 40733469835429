<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col class="mb-10" :span="24">
          <a-form-item class="mb-10">
            <a-radio-group
              :disabled="disableVoucherFields()"
              v-decorator="[
                'type',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione qual o tipo do voucher.',
                    },
                  ],
                },
              ]"
              :options="voucherTypes"
            />
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.voucher_code">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Código do Voucher
            </label>

            <a-input
              class="travel-input"
              placeholder=" Código do Vouchesr"
              :disabled="disableVoucherFields()"
              v-decorator="[
                `voucher_code`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              @change="verifyVouchersCode"
            >
              <a-icon slot="prefix" type="field-svg" />
              <template slot="suffix">
                <a-tooltip v-if="milesCardVouchers.filters.voucherCodeExists">
                  <template slot="title">
                    Este código já está sendo utilizado
                  </template>
                  <a-icon class="red" type="close" />
                </a-tooltip>
                <a-icon
                  v-if="!milesCardVouchers.filters.voucherCodeExists"
                  type="check"
                  class="cgreen"
                />
              </template>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.expires_at">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Data de Validade
            </label>

            <a-date-picker
              class="travel-input"
              placeholder="Data de Validade"
              format="DD/MM/YYYY"
              :showToday="false"
              :allowClear="true"
              v-mask="'##/##/####'"
              :disabled="disableVoucherFields()"
              v-decorator="[
                'expires_at',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.currency">
          <a-form-item class="travel-input">
            <label :class="'filled'">Moeda </label>
            <a-select
              placeholder="Moeda"
              :disabled="disableVoucherFields('currency')"
              v-decorator="[
                `currency`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option value="BRL"> R$ </a-select-option>

              <a-select-option value="USD"> U$ </a-select-option>

              <a-select-option value="EUR"> € </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.value">
          <a-form-item>
            <span class="travel-input">
              <label :class="'filled'"
                >Taxas ({{ voucherValueCurrency() }})
              </label>
              <a-input
                class="travel-input"
                placeholder="Taxas"
                :disabled="disableVoucherFields()"
                v-currency="{
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: true,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: false,
                  allowNegative: false,
                }"
                v-decorator="[
                  `value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </span>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.identifier">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Identificador
            </label>

            <a-input
              class="travel-input"
              placeholder="Identificador"
              :disabled="disableVoucherFields()"
              v-decorator="[
                `identifier`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.client_name">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Nome completo do cliente
            </label>

            <a-input
              class="travel-input"
              placeholder="Nome completo do cliente"
              :disabled="disableVoucherFields()"
              v-decorator="[
                `client_name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.airline_id">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Cia Aérea
            </label>

            <a-select
              class="travel-input"
              placeholder="Busque uma cia aérea"
              optionFilterProp="txt"
              :disabled="disableVoucherFields()"
              v-decorator="[
                `airline_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              @search="searchCustomers"
            >
              <a-select-option
                v-for="airline in airlinesList"
                :key="airline.id"
                :value="airline.id"
                :txt="airline.name"
              >
                <img v-if="airline.logo" :src="airline.logo" width="13" />
                {{ airline.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.status">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Status
            </label>

            <a-select
              class="travel-input"
              placeholder="Status"
              optionFilterProp="txt"
              v-decorator="[
                `status`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of status"
                :key="index"
                :value="item.label"
                :txt="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.voyage_id">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              ID do Voyage
            </label>

            <a-input
              class="travel-input"
              placeholder="ID do Voyage  - Opcional"
              :disabled="disableVoucherFields()"
              v-decorator="[
                `voyage_id`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.voyage_value">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Valor do Voyage
            </label>

            <a-input
              class="travel-input"
              placeholder="Valor do Voyage (R$) - Opcional"
              v-currency
              :disabled="disableVoucherFields()"
              v-decorator="[
                `voyage_value`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.receipt_date">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">
              Data de Recebimento
            </label>

            <a-date-picker
              class="travel-input"
              placeholder="Data de Recebimento"
              format="DD/MM/YYYY"
              :showToday="false"
              :allowClear="true"
              v-mask="'##/##/####'"
              :disabled="disableVoucherFields()"
              v-decorator="[
                'receipt_date',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.issuer_id">
          <a-form-item class="travel-input-outer">
            <label class="filled">Emissor</label>
            <a-select
              class="travel-input"
              placeholder="Selecione um ou mais usuários"
              optionFilterProp="txt"
              :disabled="disableVoucherFields()"
              v-decorator="[
                'issuer_id',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of usersList"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }}
                {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col class="a-center mt-20" :span="24">
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            :loading="loading"
          >
            {{ type == "create" ? "Cadastrar Voucher" : "Atualizar Voucher" }}
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-modal v-model="openCreateCustomerModal" :footer="false">
      <template slot="title">
        <a-icon class="cprimary" type="user-add" /> CRIAR CLIENTE
      </template>
      <a-form @submit.prevent="submitCustomer" :form="customerForm">
        <a-row
          class="box box-contractor"
          :gutter="[20, 0]"
          style="margin-top: -20px"
        >
          <CustomerForm
            v-if="openCreateCustomerModal"
            :customer="customer"
            :customerForm="customerForm"
          />
          <a-col class="a-center mt-20" :span="24">
            <a-button
              icon="user"
              type="primary"
              :loading="loadingSubmitCustomer"
              html-type="submit"
            >
              Cadastrar cliente
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import CustomerForm from "../../customers/forms/CustomerForm.vue";
import vouchersMixins from "@/mixins/vouchers/vouchersMixins";
import formatThings from "@/mixins/general/formatThings";

export default {
  props: {
    voucher: Object,
    type: String,
    fieldSizes: Object,
  },
  mixins: [vouchersMixins, formatThings],
  components: { CustomerForm },
  data() {
    return {
      form: this.$form.createForm(this),
      customerForm: this.$form.createForm(this),
      openCreateCustomerModal: false,
      loadingSubmitCustomer: false,
      airlinesList: [],
      customersList: [],
      usersList: [],
      status: [
        {
          label: "Auditoria",
          value: "Auditoria",
        },
        {
          label: "Ativo",
          value: "Ativo",
        },
        {
          label: "Cartão de Crédito",
          value: "Cartão de Crédito",
        },
        {
          label: "Finalizado",
          value: "Finalizado",
        },
        {
          label: "Pendente",
          value: "Pendente",
        },
        {
          label: "Perdido",
          value: "Perdido",
        },
      ],
      voucherTypes: [
        { label: "Taxas", value: "Taxas" },
        { label: "Passagem Aérea", value: "Passagem Aérea" },
        { label: "Ambos", value: "Ambos" },
      ],
      loading: false,
    };
  },
  mounted() {
    this.getAirlines();
    setTimeout(() => {
      if (this.voucher.user != undefined) {
        this.voucher.value = this.voucher.value.replace(".", ",");
        this.voucher.voyage_value = this.voucher.voyage_value.replace(".", ",");

        this.customersList = [
          {
            id: this.voucher.customer.id,
            first_name: this.voucher.customer.first_name,
            last_name: this.voucher.customer.last_name,
          },
        ];

        this.voucher.receipt_date =
          this.voucher.receipt_date != "0000-00-00"
            ? this.voucher.receipt_date
            : undefined;

        this.voucher.expires_at =
          this.voucher.expires_at != "0000-00-00"
            ? this.voucher.expires_at
            : undefined;

        this.voucher.voyage_value =
          this.voucher.voyage_value != "0.00"
            ? this.voucher.voyage_value
            : undefined;

        this.voucher.voyage_id =
          this.voucher.voyage_id != "0" ? this.voucher.voyage_id : undefined;

        this.voucher.airline_id = this.voucher.airline.id;
        this.voucher.user_id = this.voucher.user.id;
        this.voucher.issuer_id = this.voucher.issuer.id;
        this.voucher.customer_id = this.voucher.customer.id;

        this.form.setFieldsValue(this.voucher);
      }
    }, 100);

    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.usersList = data.data;
    });
  },
  methods: {
    verifyVouchersCode(val) {
      setTimeout(() => {
        if (val.target._value) {
          if (this.type == "update") {
            this.milesCardVouchers.filters.id = this.voucher.id;
          }
          this.activeTab = "";
          this.milesCardVouchers.filters.voucher_code_exact = "yes";
          this.milesCardVouchers.filters.voucher_code = val.target._value;
          this.getVouchers();
        } else {
          this.milesCardVouchers.filters.voucherCodeExists = false;
        }
      }, 100);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.value != "") {
            values.value = values.value
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(",", ".");
          }

          if (values.voyage_value != undefined) {
            values.voyage_value = values.voyage_value
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(",", ".");
          }

          values.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.loading = true;

          if (this.type == "create") {
            values.user_id = this.$store.state.userData.id;
            values.available_value = values.value;

            this.$http
              .post("/miles-card-voucher/create", values)
              .then(({ data }) => {
                data;
                this.$message.success("Voucher criado com sucesso!");
                this.loading = false;
                this.$emit("listVouchersAfterCreate", true);
              })
              .catch(({ response }) => {
                console.log(response);
                response;
                this.$message.error(response.data.message);
                this.loading = false;
              });
          }

          if (this.type == "update") {
            values.id = this.voucher.id;
            values.user_id = this.voucher.user.id;
            values.spent_value = this.voucher.spent_value;
            values.available_value = this.voucher.available_value;

            if (this.voucher.expires_at != values.expires_at) {
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.voucher.id,
                module: "miles-card-voucher",
                action: "changed-expiration-date",
                description: `${this.$store.state.userData.first_name} ${
                  this.$store.state.userData.last_name
                } alterou a DATA DE VALIDADE de ${this.formatMultiDates(
                  this.voucher.expires_at
                )}  para ${this.formatMultiDates(
                  values.expires_at
                )}  do Voucher ID ${this.voucher.id}.`,
              });
            }

            if (this.voucher.currency != values.currency) {
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.voucher.id,
                module: "miles-card-voucher",
                action: "changed-currency",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} alterou a MOEDA de ${this.voucher.currency}  para ${values.currency}  do Voucher ID ${this.voucher.id}.`,
              });

              values.available_value = values.value;
            }

            if (this.voucher.status != values.status) {
              values.action = "changed-status";
              values.modified_by.old_value = this.voucher.status;
              values.modified_by.new_value = values.status;
            }

            this.$http
              .post("/miles-card-voucher/update", values)
              .then(({ data }) => {
                data;
                this.$message.success(data.message);
                this.loading = false;
                this.$emit("getUpdatedVoucher");
              })
              .catch(({ response }) => {
                console.log(response);
                response;
                this.$message.error(response.data.message);
                this.loading = false;
              });
          }
        }
      });
    },
    getCustomers(name, id) {
      this.$http
        .get(`/customer/list?page=1&per_page=10&s=${name}&id=${id}`)
        .then(({ data }) => {
          this.customersList = data.data;
        });
    },
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          data;
          this.airlinesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    searchCustomers(term, id) {
      this.getCustomers(term, id);
    },
    voucherValueCurrency() {
      let theCurrency = this.form.getFieldValue(`currency`);
      if (!theCurrency && theCurrency == undefined) {
        theCurrency = "BRL";
      }
      return theCurrency;
    },
    disableVoucherFields(field) {
      let flag = false;

      if (this.type == "update" && this.voucher.status === "Ativo") {
        flag = true;
      }

      if (field == "currency" && this.type == "update") {
        if (this.voucher.total_transactions > 0) {
          flag = true;
        }
      }

      return flag;
    },
    addCustomer() {
      this.openCreateCustomerModal = true;
    },
    submitCustomer() {
      this.customerForm.validateFields((err, customer) => {
        console.log(err, customer);
        if (!err) {
          this.loadingSubmitCustomer = true;
          customer.action = "create-customer";
          customer.avatar = "";
          customer.status = 1;
          customer.user_id = this.$store.state.userData.id;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.openCreateCustomerModal = false;

              this.getCustomers("", data.id);

              this.form.setFieldsValue({
                customer_id: data.id,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
  },
};
</script>
