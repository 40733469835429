import _ from "lodash";
_;
export default {
  data() {
    return {
      milesCardVouchers: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          users: {
            list: [],
            selected: [],
          },
          currency: {
            list: [
              {
                name: "BRL",
              },
              {
                name: "EUR",
              },
              {
                name: "USD",
              },
            ],
            selected: [],
          },
          customers: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Pendente",
                value: "Pendente",
              },
              {
                label: "Finalizado",
                value: "Finalizado",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          voyage_id: "",
          voucher_code: "",
          client_name: "",
          voucher_code_exact: "",
          voucherCodeExists: false,
          receipt_date: "",
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
      },
    };
  },
  methods: {
    vouchersTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.milesCardVouchers.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.milesCardVouchers.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getVouchers();
    },
    changePage(current) {
      this.milesCardVouchers.pagination.page = current;
      this.getVouchers(current);
    },
    changePageSize(current, pageSize) {
      this.milesCardVouchers.pagination.page = current;
      this.milesCardVouchers.pagination.perPage = pageSize;
      this.getVouchers();
    },
    getVouchers(samePage) {
      this.milesCardVouchers.loading = true;

      let filters = "";

      if (this.milesCardVouchers.filters.users.selected.length > 0) {
        filters += `&issuer_id=${this.milesCardVouchers.filters.users.selected}`;
      }

      if (this.milesCardVouchers.filters.currency.selected.length > 0) {
        filters += `&currency=${this.milesCardVouchers.filters.currency.selected}`;
      }

      if (this.milesCardVouchers.filters.customers.selected.length > 0) {
        filters += `&customer_id=${this.milesCardVouchers.filters.customers.selected}`;
      }

      filters += `&status=${this.activeTab}`;

      if (this.milesCardVouchers.filters.voucher_code_exact == "yes") {
        filters += `&voucher_code_exact=${this.milesCardVouchers.filters.voucher_code_exact}`;
        filters += `&voucher_code_search=${this.milesCardVouchers.filters.voucher_code}`;
        filters += `&voucher_code_vid=${this.milesCardVouchers.filters.id}`;
      } else {
        if (this.milesCardVouchers.filters.voucher_code) {
          filters += `&voucher_code=${this.milesCardVouchers.filters.voucher_code}`;
        }

        if (this.milesCardVouchers.filters.id) {
          filters += `&id=${this.milesCardVouchers.filters.id}`;
        }
      }

      if (this.milesCardVouchers.filters.expires.selected.length > 0) {
        filters += `&expires=${this.milesCardVouchers.filters.expires.selected[0]}|${this.milesCardVouchers.filters.expires.selected[1]}`;
      }

      if (this.milesCardVouchers.filters.receipt_date) {
        filters += `&receipt_date=${this.milesCardVouchers.filters.receipt_date}`;
      }

      if (this.milesCardVouchers.filters.voyage_id) {
        filters += `&voyage_id=${this.milesCardVouchers.filters.voyage_id}`;
      }

      if (this.milesCardVouchers.filters.client_name) {
        filters += `&client_name=${this.milesCardVouchers.filters.client_name}`;
      }

      if (
        this.milesCardVouchers.filters.spent.min ||
        this.milesCardVouchers.filters.spent.max
      ) {
        let min = this.milesCardVouchers.filters.spent.min,
          max = this.milesCardVouchers.filters.spent.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&spent-range=${min}-${max}`;
      }

      filters += `&order=${this.milesCardVouchers.filters.order}&order-by=${this.milesCardVouchers.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card-voucher/list?page=${this.milesCardVouchers.pagination.page}&per_page=${this.milesCardVouchers.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.milesCardVouchers.pagination.page = 1;
          }
          this.milesCardVouchers.pagination.total = data.meta.total;
          this.milesCardVouchers.pagination.totalPages = data.meta.total_pages;

          this.milesCardVouchers.meta = data.meta;

          this.milesCardVouchers.list = data.data;
          this.milesCardVouchers.loading = false;

          this.milesCardVouchers.filters.voucherCodeExists = true;
        })
        .catch(({ response }) => {
          response;
          this.milesCardVouchers.pagination.total = response.data.meta.total;
          this.milesCardVouchers.pagination.totalPages =
            response.data.meta.total_pages;

          this.milesCardVouchers.meta = response.data.meta;

          this.milesCardVouchers.list = [];
          this.milesCardVouchers.loading = false;
          this.milesCardVouchers.filters.voucherCodeExists = false;
        });
    },
  },
};
